<template>
  <div class="bank">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">线上金融产品</div>
    <van-list
  v-model:loading="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
    <!-- <div class="card1" v-for="item in list2" :key="item.id" @click="navTo(item)">
      <img :src="`${'https://gxr.dsjfzj.gxzf.gov.cn/' +item.bank}`" alt="">
      <img class="img2" :src="`${'https://gxr.dsjfzj.gxzf.gov.cn/' +item.img}`" alt="">
      <p>{{item.name}}</p>
    </div> -->
      <div class="card1" v-for="item in list" :key="item" @click="navTo(list2[item])">
      <img :src="`${'https://gxr.dsjfzj.gxzf.gov.cn/' +list2[item].bank}`" alt="">
      <img class="img2" :src="`${'https://gxr.dsjfzj.gxzf.gov.cn/' +list2[item].img}`" alt="">
      <p>{{list2[item].name}}</p>
    </div>

</van-list>
    <van-popup v-model:show="show">
      <img class="w8" :src="`${'https://gxr.dsjfzj.gxzf.gov.cn/'+imgPath}`" alt="">
    </van-popup>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
   setup() {
     
    const list = ref([0,1,2,3,4,5,6,7,8,9]);
    const list2 = ref([
      {
        id: 0,
        bank: 'uploadFiles/bankImg/gsyh.png',
        img: 'static/gd/img/dkcp/edkd.jpg',
        name:'e抵快贷',
        pop: 'static/gd/img/dkcp/edkdewm.jpg'
      },
      {
        id: 1,
        bank: 'uploadFiles/bankImg/gsyh.png',
        img: 'static/gd/img/dkcp/swd.jpg',
        name:'税务贷',
        pop: 'static/gd/img/dkcp/edkdewm.jpg'
      },
      {
        id: 2,
        bank: 'uploadFiles/bankImg/nyyh.png',
        img: 'static/gd/img/dkcp/nsed.jpg',
        name:'纳税e贷',
        url: 'http://msf.gx.abchina.com/vz-cert-abc-gx/authentication.html'
      },
      {
        id: 3,
        bank: 'uploadFiles/bankImg/nyyh.png',
        img: 'static/gd/img/dkcp/dyed.jpg',
        name:'抵押e贷',
        url: 'http://www.abchina.com/cn/'
      },
      {
        id: 4,
        bank: 'uploadFiles/bankImg/nyyh.png',
        img: 'static/gd/img/dkcp/shed.jpg',
        name:'首户e贷',
        url: 'http://www.abchina.com/cn/'
      },
      {
        id: 5,
        bank: 'uploadFiles/bankImg/nyyh.png',
        img: 'static/gd/img/dkcp/bler.jpg',
        name:'保理e融',
        url: 'https://eloan.abchina.com/eloan-cli/login'
      },
      {
        id: 6,
        bank: 'uploadFiles/bankImg/zgyh.png',
        img: 'static/gd/img/dkcp/zyxyd.jpeg',
        name:'中银企E贷•信用贷',
        url: 'https://gsmecms.bankofchina.com/sme-loan/#/home'
      },
      {
        id: 7,
        bank: 'uploadFiles/bankImg/zgyh.png',
        img: 'static/gd/img/dkcp/zyysd.jpeg',
        name:'中银企E贷•银税贷',
        url: 'https://gsmecms.bankofchina.com/sme-loan/#/home'
      },
      {
        id: 8,
        bank: 'uploadFiles/bankImg/zgyh.png',
        img: 'static/gd/img/dkcp/zydyd.jpeg',
        name:'中银企E贷•抵押贷',
        url: 'https://gsmecms.bankofchina.com/sme-loan/#/home'
      },
      {
        id: 9,
        bank: 'uploadFiles/bankImg/zgyh.png',
        img: 'static/gd/img/dkcp/xsgyljr.jpg',
        name:'线上供应链金融',
        url: 'https://ebsnew.boc.cn/boccp/login.html?entryType=9'
      },
      {
        id: 10,
        bank: 'uploadFiles/bankImg/jsyh.png',
        img: 'static/gd/img/dkcp/xwqyxykd.jpg',
        name:'小微企业信用快贷',
        pop: 'static/gd/img/dkcp/xwqyxykdewm.jpg'
      },
      {
        id: 11,
        bank: 'uploadFiles/bankImg/jtyh.png',
        img: 'static/gd/img/dkcp/phed.jpg',
        name:'普惠e贷',
        url: 'https://apply.95559.com.cn/personbank/minipro/phed/index.html?WxUg5ztDmi=1630291213367#/?gzhFlag=1'
      },
      {
        id: 12,
        bank: 'uploadFiles/bankImg/gdyh.png',
        img: 'static/gd/img/dkcp/yged.jpg',
        name:'阳光e抵贷',
        pop: 'static/gd/img/dkcp/gdyhewm.jpg'
      },
      {
        id: 13,
        bank: 'uploadFiles/bankImg/gdyh.png',
        img: 'static/gd/img/dkcp/ygzcd.jpg',
        name:'阳光政采贷',
        pop: 'static/gd/img/dkcp/gdyhewm.jpg'
      },
      {
        id: 14,
        bank: 'uploadFiles/bankImg/pfyh.png',
        img: 'static/gd/img/dkcp/ysd.jpg',
        name:'银税贷',
        pop: 'static/gd/img/dkcp/pfyhewm.jpg'
      },
      {
        id: 15,
        bank: 'uploadFiles/bankImg/xyyh.png',
        img: 'static/gd/img/dkcp/kyid.jpg',
        name:'快易贷',
        url: 'https://xygj.cib.com.cn/fcsp/index.html#/cloud/productDetail?productCode=CP0000001'
      },
      {
        id: 16,
        bank: 'uploadFiles/bankImg/xyyh.png',
        img: 'static/gd/img/dkcp/kyad.jpg',
        name:'快押贷',
        url: 'https://xygj.cib.com.cn/fcsp/index.html#/cloud/productDetail?productCode=CP0000002'
      },
      {
        id: 17,
        bank: 'uploadFiles/bankImg/zxyh.png',
        img: 'static/gd/img/dkcp/ysed.jpg',
        name:'银税e贷',
        url: 'https://ifop.citicbank.com/taxloan/index.html?OPENTRANSCODE=IFOPE001#/creditgranting'
      },
      {
        id: 18,
        bank: 'uploadFiles/bankImg/zxyh.png',
        img: 'static/gd/img/dkcp/gsed.jpg',
        name:'关税e贷',
        url: 'http://www.citicbank.com/enterprise/phjr/cyljr/wm/jkgsyd/'
      },
      {
        id: 19,
        bank: 'uploadFiles/bankImg/zxyh.png',
        img: 'static/gd/img/dkcp/wled.jpg',
        name:'物流e贷车险分期',
        url: 'https://corp.bank.ecitic.com/cotb/login.html'
      },
      {
        id: 20,
        bank: 'uploadFiles/bankImg/zsyh.png',
        img: 'static/gd/img/dkcp/pjtx.jpg',
        name:'票据贴现',
        url: 'http://www.cmbchina.com/corporate/firmbank/FirmbankInfo.aspx?pageid=download'
      },
      {
        id: 21,
        bank: 'uploadFiles/bankImg/zsyh.png',
        img: 'static/gd/img/dkcp/yhcdhp.jpg',
        name:'银行承兑汇票',
        url: 'http://www.cmbchina.com/corporate/firmbank/FirmbankInfo.aspx?pageid=download'
      },
      {
        id: 22,
        bank: 'uploadFiles/bankImg/zsyh.png',
        img: 'static/gd/img/dkcp/bhsdk.jpg',
        name:'保函闪电开1.0',
        url: 'https://www.cmbchina.com/'
      },
      {
        id: 23,
        bank: 'uploadFiles/bankImg/zsyh.png',
        img: 'static/gd/img/quanwei.jpg',
        name:'付款代理',
        url: 'https://www.cmbchina.com/'
      },
      {
        id: 24,
        bank: 'uploadFiles/bankImg/bbwyh.png',
        img: 'static/gd/img/dkcp/sxd.jpg',
        name:'税信贷',
        pop: 'static/gd/img/dkcp/sxdewm.jpg'
      },
      {
        id: 25,
        bank: 'uploadFiles/bankImg/msyh.png',
        img: 'static/gd/img/dkcp/ykd.jpg',
        name:'云快贷',
        pop: 'static/gd/img/dkcp/msyhewm.jpg'
      },
      {
        id: 26,
        bank: 'uploadFiles/bankImg/msyh.png',
        img: 'static/gd/img/dkcp/wld.jpg',
        name:'网乐贷',
        pop: 'static/gd/img/dkcp/msyhewm.jpg'
      },
      {
        id: 27,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/sdd.jpg',
        name:'税银通2.0',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 28,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/kxd.jpg',
        name:'科信贷',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 29,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/zyt.jpg',
        name:'政银通',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 30,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/gfajd.jpg',
        name:'法人房产按揭贷',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 31,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/krt.jpg',
        name:'快融通',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 32,
        bank: 'uploadFiles/bankImg/gfyh.png',
        img: 'static/gd/img/dkcp/bhsst.jpg',
        name:'保函瞬时通2.0（全流程线上保函）',
        url: 'http://www.cgbchina.com.cn'
      },
      {
        id: 33,
        bank: 'uploadFiles/bankImg/payh.png',
        img: 'static/gd/img/dkcp/paszd.jpg',
        name:'数字贷',
        url: 'https://b.pingan.com.cn/kyb/apply/index.html#/public/info?o=o&productCode=80200007&projectCode=PJ002021040500559016051649371810&VNK=2f4324da&activity_FlowId=&enUmForShare=&custMaId=&channelUser=&MICRO_POSTER_ID=1e5064d889264880bb0dfcf1ff8f9b38&inputChId=epai&umNo=&channelInfo=&channelFrom=whb'
      },
      {
        id: 34,
        bank: 'uploadFiles/bankImg/payh.png',
        img: 'static/gd/img/dkcp/zced.jpg',
        name:'政采E贷',
        url: 'https://b.pingan.com.cn/kyb/apply/index.html#/info?productCode=80200011&projectCode=PJ002021062200559016051641372822&header=off&theme=wx&VNK=d6b54f84%3Fsource%3Dwhb&VNK=09687099&custMaId=&MICRO_POSTER_REFERRER_UM=&MICRO_POSTER_ID=42c785f1169240a2ad1bf54c9511300c&inputChId=epai&umNo=&channelInfo=undefined&channelFrom=whb'
      },
      {
        id: 35,
        bank: 'uploadFiles/bankImg/payh.png',
        img: 'static/gd/img/dkcp/cked.jpg',
        name:'出口E贷',
        url: 'https://b.pingan.com.cn/kyb/apply/index.html#/info?o=o&productCode=80100010&projectCode=PJ002020112400515853123689633904&VNK=89b30f89&activity_FlowId=&enUmForShare=&custMaId=&MICRO_POSTER_REFERRER_UM=&MICRO_POSTER_ID=f28d7a7fa4e34ce4b1591ee3b4e3b6cb&inputChId=epai&umNo=&channelInfo=&channelFrom=whb'
      },
      {
        id: 36,
        bank: 'uploadFiles/bankImg/yzcx.png',
        img: 'static/gd/img/dkcp/xwed.jpg',
        name:'小微易贷',
        pop: 'static/gd/img/dkcp/yzcxewm.jpg'
      },
      {
        id: 37,
        bank: 'uploadFiles/bankImg/gxnx.png',
        img: 'static/gd/img/dkcp/qsd.jpg',
        name:'企税贷',
        url: 'https://weixin.nxyqs.net/jwbank/page/index.html?path=page/06/04/P060400.html&BANK=61000001&os=web'
      },
      {
        id: 38,
        bank: 'uploadFiles/bankImg/glyh.png',
        img: 'static/gd/img/dkcp/lsd.jpg',
        name:'桂银乐税贷',
        url: 'https://perbank.guilinbank.com.cn/perbank/index.html'
      },
      {
        id: 39,
        bank: 'uploadFiles/bankImg/glyh.png',
        img: 'static/gd/img/dkcp/xwsd.jpg',
        name:'小微速贷',
        url: 'https://perbank.guilinbank.com.cn/H5Bank/H5BankWeb/views/loan/quickLoanHome.html?v=1&loanType=xwsd&subChannel=%E6%A1%82%E6%9E%97%E9%93%B6%E8%A1%8C%E9%87%91%E8%9E%8D%E6%9C%8D%E5%8A%A1%E5%85%AC%E4%BC%97%E5%8F%B7'
      }
    ]);
    const loading = ref(false);
    const finished = ref(false);
    const show = ref(false);
    const imgPath = ref('static/gd/img/dkcp/edkdewm.jpg');
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          list.value.push(list.value.length);
        }

        // 加载状态结束
        loading.value = false;

        // 数据全部加载完成
        if (list.value.length >= 40) {
          finished.value = true;
        }
      }, 500);
    };
    const navTo = (item) => {
       if(item.url!=undefined) {
         window.location.href = item.url;
       }else {
         imgPath.value = item.pop;
         show.value = true;
       }
        
      };
    return { 
      navTo,
      list,
      list2,
      onLoad,
      loading,
      finished,
      show,
      imgPath
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.bank {
  padding: 14vw 0;
  .card1 {
    display: inline-block;
    background-color: #fff;
    width: 40vw;
    margin: 3vw 0 0 4vw;
    padding: 2vw;
    border-radius: 1.5vw;
    text-align: center;
    img {
      width: 100%;
    }
    img.img2 {
      height: 32vw;
    }
    
    p {
      font-size: 4vw;
      padding: 1vw 0 2vw;
    }
  }
  .w8 {
    width: 80vw;
  }
}
</style>
